import { IInvestmentEvent } from './investment-event';

export abstract class InvestmentType {
    public name: string;
    public currentYear: number = 0;
    public events: IInvestmentEvent[] = [];

    constructor(name: string) {
        this.name = name;
    }

    public initialise(): void {
        this.addEvents(this.initialiseInternal([]));
    }

    public yearlyUpdate(): void {
        this.currentYear++;
        this.addEvents(this.yearlyUpdateInternal([]));
    }

    private addEvents(events: IInvestmentEvent[]) {
        events.forEach((event) => this.events.push({ ...event, year: event.year || this.currentYear }));
    }

    protected abstract initialiseInternal(events: IInvestmentEvent[]): IInvestmentEvent[];
    protected abstract yearlyUpdateInternal(events: IInvestmentEvent[]): IInvestmentEvent[];
}
