import { PropertyPurchase } from './property-purchase';
import { IInvestmentEvent } from './investment-event';

export class RentalProperty extends PropertyPurchase {
    public monthlyIncome: number = 0;
    public monthlyOtherExpenses: number = 0;

    protected initialiseInternal(events: IInvestmentEvent[]): IInvestmentEvent[] {
        return super.initialiseInternal(events);
    }

    public yearlyUpdateInternal(events: IInvestmentEvent[]): IInvestmentEvent[] {
        return super.yearlyUpdateInternal([
            ...events,
            { ledger: 'Cash', comment: 'Rental Income', amount: this.monthlyIncome * 12 },
            { ledger: 'Cash', comment: 'Other Expenses', amount: -1 * this.monthlyOtherExpenses * 12 },
        ]);
    }
}
