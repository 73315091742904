import { InvestmentType } from './investment-type';
import { IInvestmentEvent } from './investment-event';
import _ from 'lodash';

const monthsPerYear = 12;

export abstract class PropertyPurchase extends InvestmentType {
    public purchasePrice: number = 0;
    public mortgageDeposit: number = 0;
    public mortgageRate: number = 0.0274;
    public mortgageTerm: number = 30;
    public overpayMortgage: boolean | number = false;

    public capitalGrowth: number = 0;

    private _currentHousePrice: number | undefined;
    private _remainingMortgage: number | undefined;
    private _interestPaid: number = 0;

    protected initialiseInternal(events: IInvestmentEvent[]): IInvestmentEvent[] {
        this._currentHousePrice = this.purchasePrice;
        this._remainingMortgage = this.purchasePrice - this.mortgageDeposit;

        return [
            ...events,
            { ledger: 'Mortgage', comment: 'Initial Mortgage', amount: -1 * this.mortgageAmount },
            { ledger: 'Property Value', comment: 'Initial Purchase', amount: this.purchasePrice },
        ];
    }

    public yearlyUpdateInternal(events: IInvestmentEvent[]): IInvestmentEvent[] {
        const housePriceGrowth = _.round(this._currentHousePrice! * this.capitalGrowth, 2);
        this._currentHousePrice! += housePriceGrowth;

        const cashAvailiable = events.filter((e) => e.ledger === 'Cash').reduce((a, b) => a + b.amount, 0);

        let propertyEvents = [
            ...events,
            { ledger: 'Property Value', comment: 'Capital Growth', amount: housePriceGrowth },
        ];

        if (this._remainingMortgage) {
            const monthlyRate = this.mortgageRate / monthsPerYear;
            let desiredRepayment = this.monthlyRepayment;
            if (this.overpayMortgage === true) {
                desiredRepayment = _.floor(cashAvailiable / 12, 2);
            } else if (typeof this.overpayMortgage === 'number') {
                desiredRepayment += this.overpayMortgage;
            }

            let interestPaid = 0;
            let repayments = 0;

            for (let month = 1; month <= monthsPerYear; month++) {
                const thisMonthsInterest = _.round(this._remainingMortgage * monthlyRate, 2);
                interestPaid += thisMonthsInterest;
                this._interestPaid += thisMonthsInterest;
                this._remainingMortgage += thisMonthsInterest;

                const thisMonthsRepayment =
                    this._remainingMortgage < desiredRepayment ? this._remainingMortgage : desiredRepayment;
                repayments += thisMonthsRepayment;
                this._remainingMortgage -= thisMonthsRepayment;
            }

            propertyEvents.push(
                { ledger: 'Mortgage', comment: 'Mortgage Interest', amount: -1 * interestPaid },
                { ledger: 'Mortgage', comment: 'Mortgage Repayment', amount: repayments },
                { ledger: 'Cash', comment: 'Mortgage Repayment', amount: -1 * repayments },
            );
        }

        return propertyEvents;
    }

    private get mortgageAmount() {
        return this.purchasePrice - this.mortgageDeposit;
    }

    private _monthlyRepayment: number | undefined;
    private get monthlyRepayment(): number {
        const monthlyRate = this.mortgageRate / monthsPerYear;
        const monthlyTerm = this.mortgageTerm * monthsPerYear;
        return (
            this._monthlyRepayment ??
            (this._monthlyRepayment = _.round(
                (monthlyRate * this.mortgageAmount * Math.pow(1 + monthlyRate, monthlyTerm)) /
                    (Math.pow(1 + monthlyRate, monthlyTerm) - 1),
                2,
            ))
        );
    }
}
