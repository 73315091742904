import { InvestmentType } from './investment-types/investment-type';

export class Engine {
    public run(vehicles: InvestmentType[], years: number = 40) {
        vehicles.forEach((vehicle) => {
            vehicle.initialise();
        });

        for (let currentYear = 1; currentYear <= years; currentYear++) {
            vehicles.forEach((vehicle) => {
                vehicle.yearlyUpdate();
            });
        }
    }
}

export * from './investment-types/investment-type';
export * from './investment-types/air-bnb';
export * from './investment-types/rental-property';
export * from './investment-types/simple-investment';
