import React from 'react';
import numbro from 'numbro';

import { ResponsiveLine } from '@nivo/line';

import { AirBnB } from '../engine/investment-types/air-bnb';
import { Engine } from '../engine';
// import { SimpleInvestment } from '../engine/investment-types/simple-investment';
// import { RentalProperty } from '../engine/investment-types/rental-property';
import _ from 'lodash';

import { getNiceTickValues } from 'recharts-scale';

var daylesford = new AirBnB('Daylesford');
const daylesford_landCost = 620_000;
const daylesford_buildCost = 800_000;
daylesford.overpayMortgage = true;
daylesford.purchasePrice = daylesford_landCost + daylesford_buildCost;
daylesford.mortgageDeposit = daylesford.purchasePrice * 0.1;
daylesford.capitalGrowth = 0.0424;

const daylesford_airBnBNightlyPrice = 500;
const daylesford_airBnBNightsPerMonth = 20;
daylesford.monthlyIncome = daylesford_airBnBNightlyPrice * daylesford_airBnBNightsPerMonth;
daylesford.monthlyOtherExpenses = 800;

const years = 35;

var vehicles = [daylesford];
var engine = new Engine();
engine.run(vehicles, years);

const vehicleData = vehicles.map((vehicle) => {
    let basicData: any[] = [];

    const ledgerNames = _.uniq(vehicle.events.map((e) => e.ledger));

    for (let year = 0; year <= years; year++) {
        const lastYear: any = year === 0 ? null : basicData[year - 1];
        const yearObj: any = { year };
        let yearlyTotal = 0;

        ledgerNames.forEach((ledgerName) => {
            var ledgerEventsForYear = vehicle.events.filter((e) => e.ledger === ledgerName && e.year === year);

            yearObj[ledgerName] =
                (lastYear ? lastYear[ledgerName] : 0) +
                ledgerEventsForYear.map((e) => e.amount).reduce((a, b) => a + b, 0);

            yearObj[ledgerName + 'Events'] = ledgerEventsForYear;

            yearlyTotal += yearObj[ledgerName];
        });

        yearObj['Total'] = yearlyTotal;

        basicData[year] = yearObj;
    }

    let nivoData: { id: string; data: { x: number; y: number }[] }[] = [...ledgerNames, 'Total'].map((ledgerName) => ({
        id: ledgerName,
        data: basicData.map((value, index) => ({ x: index, y: value[ledgerName] })),
    }));

    return { vehicle, nivoData };
});

const allDataValues = vehicleData.flatMap((v) => v.nivoData.flatMap((nd) => nd.data.map((d) => d.y)));
const ticks = getNiceTickValues([_.min(allDataValues)!, _.max(allDataValues)!], 5, false);

const App = () => (
    <div style={{ width: '80%', margin: 'auto' }}>
        {vehicleData.map(({ vehicle, nivoData }) => {
            return (
                <div key={vehicle.name} style={{ height: '400px' }}>
                    <h3>{vehicle.name}</h3>
                    <ResponsiveLine
                        data={nivoData}
                        margin={{ top: 10, right: 50, bottom: 50, left: 70 }}
                        yScale={{ type: 'linear', min: ticks[0], max: ticks[ticks.length - 1] }}
                        colors={{ scheme: 'nivo' }}
                        animate={true}
                        axisLeft={{
                            tickValues: ticks,
                            format: (number) =>
                                `${numbro(number).formatCurrency({
                                    mantissa: 2,
                                    thousandSeparated: true,
                                    optionalMantissa: true,
                                })}`,
                        }}
                        yFormat={(number) =>
                            `${numbro(number).formatCurrency({
                                mantissa: 2,
                                thousandSeparated: true,
                                optionalMantissa: true,
                            })}`
                        }
                        pointSize={5}
                        pointColor={{ theme: 'background' }}
                        pointBorderWidth={1}
                        pointBorderColor={{ from: 'serieColor' }}
                        enableSlices="x"
                        useMesh={true}
                    />
                </div>
            );
        })}
    </div>
);

export default App;
