import { IInvestmentEvent } from './investment-event';
import { InvestmentType } from './investment-type';
import _ from 'lodash';

export class SimpleInvestment extends InvestmentType {
    public initialDeposit: number = 0;
    public yearlyInterest: number = 0;

    private _currentInvestmentPot: number | undefined;

    protected initialiseInternal(events: IInvestmentEvent[]): IInvestmentEvent[] {
        return [...events, { ledger: 'Account', comment: 'Initial Deposit', amount: this.initialDeposit }];
    }

    public yearlyUpdateInternal(events: IInvestmentEvent[]): IInvestmentEvent[] {
        if (this._currentInvestmentPot === undefined) {
            this._currentInvestmentPot = this.initialDeposit;
        }

        const yearlyIncrease = _.round(this._currentInvestmentPot * this.yearlyInterest, 2);
        this._currentInvestmentPot += yearlyIncrease;

        return [...events, { ledger: 'Account', comment: 'Interest', amount: yearlyIncrease }];
    }
}
