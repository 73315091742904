import * as engine from '../../engine';

interface IVehicleReducer {}

interface IAddVehicleReducer extends IVehicleReducer {
    type: 'ADD_VEHICLE';
    name: string;
    vehicle: 'AirBnB' | 'RentalProperty' | 'SimpleInvestment';
}

interface IUpdateVehicleReducer extends IVehicleReducer {
    type: 'UPDATE_VEHICLE';
    name: string;
    vehicle: Partial<engine.InvestmentType>;
}

const vehicles = (state: engine.InvestmentType[] = [], action: IAddVehicleReducer | IUpdateVehicleReducer) => {
    switch (action.type) {
        case 'ADD_VEHICLE':
            const typeMap = {
                [nameof<engine.AirBnB>()]: engine.AirBnB,
                [nameof<engine.RentalProperty>()]: engine.RentalProperty,
                [nameof<engine.SimpleInvestment>()]: engine.SimpleInvestment,
            };

            const newEngineType = typeMap[action.vehicle];
            const newEngine = new newEngineType(action.name);

            return [...state, newEngine];
        case 'UPDATE_VEHICLE':
            return state.map((vehicle) => (vehicle.name === action.name ? { vehicle, ...action.vehicle } : vehicle));
        default:
            return state;
    }
};

export default vehicles;
